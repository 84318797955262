<template>
  <div class="deformation">
    <div class="deformation_search">
      <div class="deformation_search_title">变形宝典,让字体设计更简单</div>
      <!-- wow animate__animated animate__fadeInUp -->
      <div class="index_search" ref="searchBox">
        <div class="search_input">
          <input type="text" v-model="keywords" @focus="focusFun" @blur="blurFun" placeholder="请输入4个以内的汉字" maxlength="4"
            @keyup.enter="searchFun" />
        </div>
        <img v-if="keywords" @click="(keywords = ''), (isShow = true)" class="deformation_search_del"
          src="../assets/images/search_del.png" alt="" />
        <div class="index_search_button" @click="searchFun">
          <img src="../assets/images/indexsearch.png" alt="" />
        </div>
        <!-- <img class="deformation_search_img1" src="../assets/images/bianxing1.png" alt="">
                <img class="deformation_search_img2" src="../assets/images/bianxing2.png" alt=""> -->
      </div>
      <div class="search_history" v-show="isHistory && history.length > 0">
        <div class="search_history_list">
          最近搜索：<template v-for="(item, index) in history">
            <span @click="historyFun(item)" v-if="index < 5" :key="index">{{ item }}</span>
          </template>
        </div>
        <div class="search_history_clear" @click="delHistoryFun">
          <img src="../assets/images/clear.png" alt="" />
          清除
        </div>
      </div>
      <div class="deformation_tip">*每天可下载50个字体变形源文件</div>
      <!-- <div class="deformation_text">本书共含3684个字的设计变形，每个字体10种变形方式，共36840个字体变形，每个字体变形，读者均可商用</div> -->
    </div>
    <div class="mian" v-show="isShow">
      <div class="deformation_case">
        <div class="deformation_case_title">
          <span>
            变形宝典案例
            <img class="deformation_case_title_img1" src="../assets/images/icon_title@2x.png" alt="" />
            <img class="deformation_case_title_img2" src="../assets/images/icon_title@2x.png" alt="" />
          </span>

          <img @click="isPpFun" class="deformation_case_title_pp" src="../assets/images/pianpang.png" alt="">
        </div>
        <div class="deformation_case_ul clearfix">
          <!-- wow animate__animated animate__fadeInUp -->
          <div class="deformation_case_ul2" v-for="(item2, index2) in list" :key="index2">
            <div class="fl deformation_case_li" :style="{ animationDelay: 0.1 * index + 's' }"
              v-for="(item, index) in item2" :key="index">
              <div class="deformation_case_li_box">
                <img v-lazy="item.thumb" :key="item.thumb" alt="" />
                <!-- <img src="../assets/images/d1.png" alt=""> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="search" v-show="!isShow">
      <div class="ppclass_box" v-show="isPp">
        <div class="ppclass" style="width: 63px;padding-left: 0;padding-right: 0;text-align: center;"
          :class="ppkeywords == '' ? 'active' : ''" @click="ppSearchFun('')">全部</div>
        <div class="ppclass" :class="ppkeywords == item ? 'active' : ''" @click="ppSearchFun(item)"
          v-for="(item, index) in keywordsList" :key="index">{{ item }}</div>
      </div>
      <template v-if="searchListShow">
        <div class="search_list clearfix" v-for="(item, index) in searchList" :key="index">
          <div class="fl search_item" v-for="(items, indexs) in item.list" :key="indexs"
            :ref="'getRightRefs' + index + '_' + indexs" @mouseenter="rightIfFun(index + '_' + indexs)"
            @mouseleave="activeIndex = -1">
            <div class="search_item_img">
              <img v-lazy="items.thumb" :key="items.thumb" alt="" />
            </div>
            <div class="search_item_mask"></div>
            <div class="search_item_big" :class="showDirection ? 'left' : ''"
              :style="{ left: !showDirection ? clientWidth + 'px' : 'auto', right: showDirection ? clientWidth + 'px' : 'auto' }"
              v-if="activeIndex == index + '_' + indexs">

              <div class="search_item_big_box">
                <img v-lazy="items.thumb" :key="items.thumb" alt="" />
                <div class="search_item_download" @click="downFun(items.id)"></div>
                <div :class="items.is_down_cart == 1
                    ? 'search_item_add search_item_add2'
                    : 'search_item_add'
                  " @click="jionFun(items.id, index, indexs)"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="loading" v-if="isloadppShow">
          正在加载中
          <img src="../assets/images/jiazai.png" alt="" />
        </div>
      </template>

      <el-empty v-else description="暂无数据"></el-empty>
    </div>

    <binding v-if="popStatus" :type="0"></binding>
    <!-- 侧边导航 -->
    <sidebar ref="downtotal"></sidebar>
    <successPop v-if="randomState" ref="success" type="baodian" :showarr="[false, true]"></successPop>
  </div>
</template>

<script>
import binding from "../components/binding.vue";
import sidebar from "../components/sidebar.vue";
import successPop from "../components/successPop.vue";
export default {
  name: "deformation",
  data() {
    return {
      keywords: "", //搜索关键词
      list: [], //列表

      isShow: true,
      searchList: [],
      activeIndex: -1,

      history: [], //搜索历史
      isHistory: false, //显示搜索历史

      showDirection: false,

      searchListShow: false,

      popStatus: false,

      total: 0,

      qq: "",
      randomState: false,

      clientWidth: 146,//大字偏移量

      pagepp: 1,
      isloadpp: false,//加载防抖
      isloadppShow: false,//是否显示加载中
      keywordsList: [],
      ppkeywords: '',
      isPp: false,//是否显示偏旁分类
    };
  },
  components: {
    binding,
    sidebar,
    successPop,
  },
  mounted() {
    window.onscroll = this.pageScroll;

    this.keywords = "";
    if (localStorage.getItem("total") && localStorage.getItem("access_token")) {
      this.total = localStorage.getItem("total");
    } else {
      this.total = 0;
      if (localStorage.getItem("access_token")) {
        this.listFun();
      }
    }
    // if (!localStorage.getItem("access_token")) {
    //   this.$parent.loginShow();
    //   return;
    // } else {
    //   let userInfo = JSON.parse(localStorage.getItem("userInfo"));
    //   console.log(userInfo);
    //   if (userInfo.is_baodian != 1) {
    //     this.$router.push({
    //       name: "refresh",
    //       query: {
    //         usernext: "deformationBind",
    //       },
    //     });
    //     return;
    //   }
    // }

    this.$parent.routerIndex = 4;
    this.$parent.showIndex = 1;
    if (localStorage.getItem("history")) {
      this.history = localStorage.getItem("history").split(",");
      if (this.history.length > 4) {
        this.history = this.history.splice(0, 4);
      }
    }
    if (!localStorage.getItem("access_token")) {
      this.$parent.loginShow();
      this.isShow = true;
      this.caseFun();
      console.log(1);
    } else {
      let userInfo = JSON.parse(localStorage.getItem("userInfo"));
      if (userInfo.is_baodian != 1) {
        //this.popStatus=true;
        this.$router.push({
          name: "deformationBind",
        });
        return;
      }

      if (localStorage.getItem("access_token") && this.$route.query.search) {
        this.keywords = this.$route.query.search;
        this.searchFun();
      } else {
        this.isShow = true;
        this.caseFun();
        console.log(4);
      }
    }
  },
  methods: {
    //获取清单数量
    listFun() {
      var that = this;
      this.$api.POST(this.$face.download_cartIndex, "", function (res) {
        that.total = res.data.total;
        localStorage.setItem("total", res.data.total);
      });
    },
    contactqq() {
      //window.open('tencent://message/?Menu=yes&uin=' + this.qq + '&Site=https://new.zitisheji.com');tencent://message/?Menu=yes&uin={$footer['qq']|default=''}&Service=300&sigT=45a1e5847943b64c6ff3990f8a9e644d2b31356cb0b4ac6b24663a3c8dd0f8aa12a595b1714f9d45
      //window.open('tencent://message/?uin='+this.qq+'&Site=https://new.zitisheji.com&Menu=yes');
      window.open(
        "http://dct.zoosnet.net/LR/Chatpre.aspx?id=DCT52374225&lng=cn"
      );
    },
    jumpFun(url, param) {
      let routeData = this.$router.resolve({
        name: url,
        query: {
          id: param,
        },
      });
      window.open(routeData.href, "_blank");
    },
    // 回到顶部
    topBackFun() {
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果

      let space = 50;
      if (top >= 1000 && top < 2000) {
        space = 50;
      } else if (top >= 2000 && top < 3000) {
        space = 80;
      } else if (top >= 3000 && top < 4000) {
        space = 110;
      } else if (top >= 4000 && top > 6000) {
        space = 150;
      } else if (top >= 6000 && top > 10000) {
        space = 180;
      }

      const timeTop = setInterval(() => {
        document.body.scrollTop =
          document.documentElement.scrollTop =
          top -=
          space;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
    },
    caseFun() {
      var that = this;
      this.$api.POST(this.$face.materialCases, "", function (res) {
        that.list = that.$utile.listData(res.data);
        console.log(that.list);
      });
    },

    searchFun() {
      this.isPp = false;
      this.isloadpp = false;
      this.ppkeywords = '';
      this.pagepp = 1;
      this.searchList = [];
      if (this.keywords == "") {
        this.isShow = true;
        return;
      }

      this.history.forEach((item, index) => {
        if (item == this.keywords) {
          this.history.splice(index, 1);
        }
      });
      this.history.unshift(this.keywords);
      localStorage.setItem("history", this.history.join(","));
      this.isHistory = false;
      var that = this;
      this.$api.POST(
        this.$face.materialBaodian,
        { keywords: this.keywords },
        function (res) {
          that.isShow = false;
          that.searchList = res.data;
          var s = 0;
          that.searchList.forEach((item) => {
            if (item.list) {
              if (item.list.length > 0) {
                s++;
              }
            }
          });
          if (s != 0) {
            that.searchListShow = true;
          }
        }
      );
    },

    // 获取焦点
    focusFun() {
      this.isHistory = true;
    },
    blurFun() {
      setTimeout(() => {
        this.isHistory = false;
      }, 200);
    },
    // 搜索历史记录
    historyFun(e) {
      this.keywords = e;
      this.searchFun();
    },

    //加入清单
    jionFun(id, index, indexs) {
      var that = this;
      var params = {
        material_id: id,
        type: 2,
      };
      this.$api.POST(this.$face.download_cartJoin, params, function (res) {
        that.$utile.prompt("success", res.msg);

        if (res.msg == "加入清单成功") {
          that.searchList[index]["list"][indexs].is_down_cart = 1;
        } else {
          that.searchList[index]["list"][indexs].is_down_cart = 0;
        }
        that.$refs.downtotal.listFun();
        //that.listFun();
      });
    },
    // 下载源文件
    downFun(id) {
      var that = this;
      var params = {
        id: id,
      };
      this.$api.POST(this.$face.downloadDown, params, function (res) {
        if(res.data.down_code == 100){
          that.$message({
            message: res.data.msg,
            type: "error",
          });
          return ;
        }else{
          const a = document.createElement("a");
          //   let url = baseUrl + binding.value // 若是不完整的url则需要拼接baseURL
          a.href = res.data.down_url; // 完整的url则直接使用
          // document.body.appendChild(a)
          a.click();
          setTimeout(() => {
            that.randomState = true;
          }, 200);
        }
        //that.$utile.prompt("success", res.data.msg);
      });
    },
    // 下载源文件
    downTestFun(id, title) {
      var that = this;
      var params = {
        id: id,
      };
      this.$api.download(this.$face.downloadDownTest, params, function (res) {
        console.log(res);
        that.downloadFile(res, title + id);
      });
    },
    downloadFile(data, row) {
      let blob = new Blob([data], { type: "application/zip" });
      let url = window.URL.createObjectURL(blob);
      const link = document.createElement("a"); // 创建a标签
      link.href = url;
      link.download = row + '.zip'; // 重命名文件
      link.click();
      URL.revokeObjectURL(url); // 释放内存
      this.randomState = true;
      // let url = window.URL.createObjectURL(new Blob([data]));
      // let link = document.createElement("a");
      // link.style.display = "none";
      // link.href = url;
      // console.log(row);
      // link.setAttribute("download", "row");
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link); // 下载完成移除元素
      // window.URL.revokeObjectURL(url); // 释放掉blob对象
    },
    // 获取距离右侧距离
    rightIfFun(i) {
      this.clientWidth = this.$refs["getRightRefs" + i][0].clientWidth
      let index = i.split("_")[1];
      if (index >= 7) {
        this.showDirection = true;
      } else {
        if (
          window.innerWidth - this.$refs["getRightRefs" + i][0].offsetLeft <=
          521
        ) {
          this.showDirection = true;
        } else {
          this.showDirection = false;
        }
      }
      this.activeIndex = i;
    },

    // 清空历史记录
    delHistoryFun() {
      this.history = [];
      localStorage.removeItem("history");
    },


    // 查看偏旁
    isPpFun() {
      this.isPp = true;
      var that = this;
      this.$api.POST(
        this.$face.materialBaodianpp,
        {
          keywords: this.ppkeywords,
          page: this.pagepp,
          limit: 100
        },
        function (res) {
          that.isShow = false;
          if (that.pagepp == 1) {
            that.searchList = res.data;
            that.keywordsList = res.data[0].keywords
          } else {
            setTimeout(() => {
              that.isloadppShow = false;
              that.searchList[0].list = that.searchList[0].list.concat(res.data[0].list);
            }, 500)

          }
          if (res.data[0].list.length == 100) {
            that.isloadpp = true
            that.pagepp++
          } else {
            that.isloadppShow = false;
            that.isloadpp = false
          }

          that.searchListShow = true;

          // var s = 0;
          // that.searchList.forEach((item) => {
          //   if (item.list) {
          //     if (item.list.length > 0) {
          //       s++;
          //     }
          //   }
          // });
        }
      );
    },
    ppSearchFun(e) {
      this.ppkeywords = e;
      this.pagepp = 1;
      this.isPpFun()
    },

    pageScroll() {

      // 获取滚动的距离
      let scrollTop = document.documentElement.scrollTop;
      // 获取滚动的高度（获取整个html的高度）
      let scrollHeight = document.documentElement.scrollHeight;
      // 获取屏幕(浏览器)高度
      let clienHeight = document.documentElement.clientHeight;
      // 滚动的距离 + 屏幕高度 - 内容高度 >= 0 表示滚动到底部了      (下拉加载判断条件)
      if (scrollTop + clienHeight - scrollHeight >= -400 && this.isloadpp) {
        this.isloadpp = false;
        this.isloadppShow = true;
        console.log("我到底了");
        // //滚动至底部后请求数据
        this.isPpFun();
      }
    }
  },
  activated() {
    this.$nextTick(() => {
      this.randomState = false;
      this.$parent.routerIndex = 4;
      this.$parent.showIndex = 1;
      //判断是否点击的导航，导航清空筛选项
      if (localStorage.getItem("clearAll") == 1) {
        this.navId = "";
        this.navIndex = 0;
        this.navList.forEach((element) => {
          element.index = 0;
          element.isShow = false;
        });
        this.keywords = '';
        this.isShow = true;
        this.isPp = false;
        this.isloadpp = false;
        this.ppkeywords = '';
        this.pagepp = 1;
      }
      this.caseFun();
    });
  },
  computed: {
    right1() {
      var left = 0;
      if (window.innerWidth - 1400 > 128) {
        left = ((window.innerWidth - 1400) / 2 - 64) / 2;
      } else {
        left = 0;
      }
      console.log(left);
      return left;
    },
  },
};
</script>

<style scoped>
.deformation {
  background: #fff;
}

.deformation_search {
  height: 351px;
  /* background: url(../assets/images/bianxingbaodian.png) no-repeat; */
  background: url(../assets/images/bd_banner.jpg) no-repeat top center;
  background-repeat: repeat-x;
  padding-top: 102px;
  box-sizing: border-box;
  position: relative;
}

.deformation_case {
  margin-top: 30px;
}

.deformation_tip {
  display: block;
  position: absolute;
  left: 18%;
  font-size: 14px;
  bottom: 30px;
  color: #999;
  text-align: left;
}

.deformation_text {
  display: block;
  position: absolute;
  left: 50%;
  font-size: 14px;
  bottom: 48px;
  color: #999;
  transform: translateX(-50%);
  text-align: left;
}

.deformation_search_title {
  font-size: 30px;
  line-height: 30px;
  letter-spacing: 0px;
  color: #333333;
  text-align: center;
  margin-bottom: 41px;
}

/* 搜索 */
.index_search {
  width: 792px;
  height: 70px;
  background-color: #ffffff;
  box-shadow: -1px 1px 19px 2px rgba(0, 0, 0, 0.07);
  border-radius: 10px;
  border: solid 1px #5957ff;
  margin: 0 auto;
  position: relative;
  z-index: 90;
  padding-right: 160px;
  box-sizing: border-box;
  display: flex;
}

.search_input {
  width: calc(100% - 120px);
}

.search_input input {
  display: block;
  width: 100%;
  height: 100%;
  font-size: 14px;
  color: #666;
  padding-left: 14px;
  box-sizing: border-box;
  font-size: 16px;
  border-radius: 10px;
}

.search_input input::placeholder {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: 0px;
  color: #999999;
}

.index_search_button {
  position: absolute;
  top: 2px;
  right: 2px;
  width: 150px;
  height: 64px;
  background-image: linear-gradient(0deg, #5957ff 0%, #9998ffc7 100%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.index_search_button img {
  width: 29px;
  height: 31px;
}

.deformation_search_img1 {
  position: absolute;
  top: -10px;
  right: -100px;
  z-index: -1;
}

.deformation_search_img2 {
  position: absolute;
  top: -10px;
  right: 18px;
  z-index: -1;
}

.deformation_search_del {
  width: 17px;
  height: 17px;
  position: absolute;
  top: 27px;
  right: 173px;
  z-index: 11;
  cursor: pointer;
}

.search_history {
  width: 792px;
  line-height: 40px;
  background-color: #ffffff;
  box-shadow: -1px 1px 19px 2px rgba(0, 0, 0, 0.07);
  border-radius: 8px;
  margin: 9px auto 0;
  position: relative;
  z-index: 90;
  padding: 5px 76px 5px 23px;
  box-sizing: border-box;
}

.search_history_list {
  font-size: 15px;
  letter-spacing: 0px;
  color: #999999;
}

.search_history_list span {
  color: #666666;
  margin: 0 15px;
  cursor: pointer;
  transition: 0.3s;
}

.search_history_list span:hover {
  color: #5957ff;
  text-decoration: underline;
}

.search_history_clear {
  display: flex;
  align-items: center;
  position: absolute;
  top: 5px;
  right: 16px;
  font-size: 14px;
  color: #999999;
  cursor: pointer;
}

.search_history_clear img {
  margin-right: 2px;
}

.deformation_case_title {
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  line-height: 24px;
  letter-spacing: 0px;
  color: #333333;
  text-align: center;
  margin-bottom: 39px;
  position: relative;
}

.deformation_case_title_pp {
  position: absolute;
  right: 0;
  top: -40px;
  cursor: pointer;
  width: 217px;
  height: 84px;
}

.deformation_case_title span {
  position: relative;
}

.deformation_case_title_img1 {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -52px;
  margin: auto;
}

.deformation_case_title_img2 {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -52px;
  margin: auto;
  transform: rotateY(180deg);
}

.deformation_case_ul {
  margin: 0 -13.33px 6px;
  display: flex;
}

.deformation_case_ul2 {
  width: 330px;
  margin: 0 13.33px 0;
}

.deformation_case_li {
  border-radius: 10px;
  margin-bottom: 28px;
  background-color: #fff;
}

.deformation_case_li_box {
  width: 330px;
  border-radius: 10px;
  overflow: hidden;
  position: relative;
}

.deformation_case_li_box img {
  width: 100%;
  transition: 0.3s;
}

.deformation_case_li:hover .deformation_case_li_box img {
  transform: scale(1.08);
}

/* 搜索结果 */
.search {
  width: 1596px;
  margin: 10px auto 0;
  padding-bottom: 62px;
  min-height: 200px;
}

.search_list {
  /* margin-bottom: 33px; */
}

.search_item {
  width: 147px;
  height: 147px;
  background-color: #ffffff;
  border-radius: 10px;
  border: solid 1px #dddddd;
  box-sizing: border-box;
  position: relative;
  margin-right: 14px;
  margin-bottom: 33px;
}

.search_item:nth-child(10n) {
  margin-right: 0;
}

.search_item_img {
  width: 145px;
  height: 145px;
  border-radius: 10px;
  overflow: hidden;
}

.search_item img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.search_item_img>img {
  transform: scale(1.4);
}

.search_item_mask {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 10px;
  transition: 0.3s;
}

.search_item:hover .search_item_mask {
  background-color: rgba(0, 0, 0, 0.1);
}

.search_item_big {
  width: 370px;
  height: 350px;
  position: absolute;
  top: -31px;
  left: 146px;
  z-index: 10;
  padding-left: 20px;
  box-sizing: border-box;
}

.search_item_big.left {
  left: auto;
  right: 146px;
  padding-right: 20px;
}

.search_item_big_box {
  width: 350px;
  height: 350px;
  background-color: #ffffff;
  box-shadow: 1px 1px 24px 0px rgba(0, 0, 0, 0.09);
  border-radius: 10px;
  position: relative;
  overflow: hidden;
}

.search_item_big_box img {
  width: 550px;
  height: 550px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -275px;
  margin-left: -275px;
}

.search_item_download {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 15px;
  right: 14px;
  background: url(../assets/images/baodianxiazai.svg) no-repeat;
  background-size: 100% 100%;
  cursor: pointer;
}

.search_item_add {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 65px;
  right: 14px;
  background: url(../assets/images/addqingdan.svg) no-repeat;
  background-size: 100%;
  cursor: pointer;
}

.search_item_add2 {
  background: url(../assets/images/addqingdan2.svg) no-repeat !important;
  background-size: 100%;
}

.search_item_download:hover,
.search_item_add:hover {
  opacity: 0.8;
}

.sidebar_r {
  right: 5%;
  bottom: 120px;
  left: auto;
}

@media (max-width: 1596px) {
  .search {
    width: 90%;
  }

  .search_item {
    width: 9%;
    height: auto;
    margin-right: 1%;
  }

  .search_item_img {
    width: 100%;
    height: 100%;
  }
}


.loading {
  width: 100%;
  height: 50px;
  text-align: center;
  font-size: 20px;
  color: #999999;
  text-align: center;
  box-sizing: border-box;
}

.loading img {
  width: 24px;
  height: 24px;
  display: inline-block !important;
  vertical-align: middle;
  animation: fadeum 5s infinite;
}

@keyframes fadeum {
  0 {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ppclass_box {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
}

.ppclass {
  padding: 6px 9px;
  cursor: pointer;
  font-size: 15px;
}

.ppclass.active {
  background: #ebebff;
  border-radius: 6px;
  color: #5957ff;
}
</style>